/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable import/order */
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';

import Icon from '../../atoms/Icon';
import InputWrapper from '../InputWrapper';
import Label from '../Label';
import Stout from '../../typography/Stout';
import sharedStyles from '../sharedStyles.module.scss';
import Bolded from '../../typography/Bolded';
import Content from '../../typography/Content';
import Section from '../../layout/Section';

import styles from './styles.module.scss';

/** NOTE:
 * If the styling on the input does not match what's expected(e.g it's missing a "styles__simple__<COMPILED STUFF HERE>" class)
 * ... then try adding the form's formKey value
 */
class Input extends Component {
  constructor(props) {
    super(props);

    this.state = { previousError: '', focused: false };

    this.inputRef = React.createRef();
  }

  static defaultProps = {
    autoFocus: false,
    disabled: false,
    inline: false,
    full: false,
    large: false,
    placeholder: '',
    type: 'text',
    forceRequired: false,
  };

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.addEventListener('wheel', this.handleOnWheel, { passive: false });
    }
  }

  handleOnWheel = (e) => {
    if (this.props.type === 'number') {
      e.target.blur();
    }
  };

  handleChange = (e) => {
    const target = e.target;

    let value = target.value;
    if (this.props.fieldKey === 'email') {
      value = target.value.trim();
    }

    this.props.setField(this.props.formKey, this.props.fieldKey, value, true);
  };

  onFocus = () => {
    this.props.onFocus && this.props.onFocus();

    this.setState({ focused: true });
  };

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();

    this.setState({ focused: false });
  };

  componentDidUpdate(prevProps) {
    if (get('props.clientValidation.valid', this) !== get('clientValidation.valid', prevProps)) {
      this.setState({
        previousError: get('clientValidation.message', prevProps) || this.state.previousError,
      });
    }
  }

  dirty = () => {
    return !!this.props.dirty;
  };

  // Show if the field has an error and is dirty
  showValidation = () => {
    if (this.props.type === 'number') {
      return !!get('clientValidation.message', this.props) && !!this.dirty();
    }
    return !!get('clientValidation.message', this.props) && (!!this.dirty() || !!this.props.value);
  };

  // Material theme inputs need a specific placholder
  placeholder = () => {
    if (this.props.theme === 'material') {
      return ' ';
    }

    return this.props.placeholder || '';
  };

  errors = () => {
    let message = this.state.previousError;
    const valid = getOr(true, 'props.clientValidation.valid', this);

    const showErrors = !valid && this.state.focused && this.dirty();
    // don't show errors if focused, or if valid
    if (!showErrors) {
      return null;
    }

    message = get('props.clientValidation.message', this);
    const showError = !isEmpty(message) && !valid && this.state.focused && this.dirty();

    return (
      showError && (
        <div
          role="alert"
          className={classnames(sharedStyles.errorMessage, {
            [sharedStyles.show]: showError,
            [sharedStyles.hide]: !showError,
            [sharedStyles[this.props.theme]]: this.props.theme,
          })}
        >
          <Stout uppercase={false} status="error">
            {message}
          </Stout>
        </div>
      )
    );
  };

  ariaLabel = () => {
    if (this.props.ariaLabel) return this.props.ariaLabel;
    if (typeof this.props.label === 'string') return this.props.label;
    return 'input field';
  };

  render() {
    return (
      <InputWrapper
        full={this.props.full}
        large={this.props.large}
        inline={this.props.inline}
        valid={this.showValidation()}
        theme={this.props.theme}
        fixedSpacing={this.props.fixedSpacing}
      >
        {this.props.description && (
          <Section size="sm">
            <Content inheritColor={this.props.descriptionInheritColor}>
              <Bolded>{this.props.descriptionIntro}</Bolded>
              {this.props.description}
            </Content>
          </Section>
        )}
        {this.errors()}
        <input
          ref={this.inputRef}
          data-cy={this.props.dataCy ? this.props.dataCy + '-input' : ''}
          id={`${this.props.formKey}-${this.props.fieldKey}`}
          aria-label={this.ariaLabel()}
          autoComplete={this.props.autoComplete || 'off'}
          autoFocus={this.props.autoFocus}
          className={classnames(sharedStyles.inputField, styles.input, {
            [sharedStyles.full]: this.props.full,
            [sharedStyles.invalid]: this.showValidation(),
            [sharedStyles[this.props.theme]]: this.props.theme,
            [sharedStyles.disabled]: this.props.disabled,
            [sharedStyles.fixedHeight]: this.props.fixedHeight,
          })}
          type={this.props.type}
          disabled={this.props.disabled}
          onChange={this.props.onChange || this.handleChange}
          value={this.props.value}
          placeholder={this.placeholder()}
          required={this.props.forceRequired || this.props.required}
          max={this.props.max}
          min={this.props.min}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onInput={this.props.onInput}
          {...this.props.inputProps}
        />
        {this.props.descriptionAbove && (
          <Section size="sm">
            <Content inheritColor={this.props.descriptionAboveInheritColor}>
              <Bolded>{this.props.descriptionAboveIntro}</Bolded>
              {this.props.descriptionAbove}
            </Content>
          </Section>
        )}
        {this.props.label && (
          <Label
            disabled={this.props.disabled}
            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            theme={this.props.theme}
            bolded={this.props.bolded}
            descriptionAbovePresent={this.props.descriptionAbove}
            forceRequired={this.props.forceRequired}
          >
            {this.props.icon && <Icon type={this.props.icon} />}
            {this.props.bolded ? (
              <span className={styles.bolded}>
                <Bolded>{this.props.label}</Bolded>
              </span>
            ) : (
              <Stout inherit={this.props.inherit} inline>
                {this.props.label}
              </Stout>
            )}
          </Label>
        )}
      </InputWrapper>
    );
  }
}

export { Input };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(Input);
