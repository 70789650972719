//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Subtitle = (props) => (
  <div
    data-cy={props.dataCy ? props.dataCy + '-subtitle' : ''}
    className={classnames(styles.subtitle, props.className, {
      [styles[props.status]]: props.status,
      [styles.bold]: props.bold,
      [styles.semibold]: props.semibold,
      [styles.italic]: props.italic,
      [styles.underline]: props.underline,
      [styles.center]: props.center,
      [styles.noMargin]: props.noMargin,
      [styles.smMargin]: props.smMargin,
      [styles.inheritColor]: props.inheritColor,
      [styles.inline]: props.inline,
      [styles.dark]: props.dark,
    })}
  >
    {props.children}
  </div>
);

export default Subtitle;
