import { useEffect } from 'react';

const useOutsideClick = (ref, callback, modalOpen) => {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (ref?.current && !ref.current.contains(event.target) && modalOpen) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [ref, modalOpen]);
};

export default useOutsideClick;
