//
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import Fuse from 'fuse.js';

import FlexCol from '../../layout/FlexCol';
import InputWrapper from '../InputWrapper';
import Label from '../Label';
import Icon from '../../atoms/Icon';
import Stout from '../../typography/Stout';
import Section from '../../layout/Section';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { setField, setTypeaheadSearchValue } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

class StaticTypeahead extends Component {
  static defaultProps = {
    disabled: false,
    inline: false,
    full: false,
    placeholder: '',
    type: 'text',
    required: false,
  };

  fuse;
  dropdown;
  state = { options: [] };

  constructor(props) {
    super(props);

    this.fuse = new Fuse(this.props.options, {
      threshold: 0.35,
      shouldSort: true,
      keys: this.props.searchKeys,
    });
  }

  componentDidMount() {
    if (document) {
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('keydown', this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener('mousedown', this.handleClickOutside);
    }
  }

  handleFocus = () => {
    let search = '';

    const { value, displayKey } = this.props;

    if (value) {
      if (typeof value === 'object') {
        search = value[displayKey];
      } else {
        search = value;
      }
    }

    this.getResults(search);
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 9) {
      this.handleBlurred();
    }
  };

  handleClickOutside = (e) => {
    if (this.dropdown && !this.dropdown.contains(e.target)) {
      this.handleBlurred();
    }
  };

  handleBlurred = () => {
    this.clearResults();

    const { value, displayKey, searchValue } = this.props;
    const display = typeof value === 'object' ? value[displayKey] : value;

    if (this.props.searchValue === '') {
      this.props.setField(this.props.formKey, this.props.fieldKey, '', true);
    } else {
      // If the field blurred without setting a new value, reset the
      // search value to indicate there is no value set.
      if (display !== searchValue) {
        this.props.setTypeaheadSearchValue(this.props.formKey, this.props.fieldKey, value || '');
      }
    }
  };

  handleChange = (e) => {
    const target = e.target;

    this.getResults(target.value);

    this.props.setTypeaheadSearchValue(this.props.formKey, this.props.fieldKey, target.value);
  };

  handleClickCloseIcon = () => {
    this.props.setField(this.props.formKey, this.props.fieldKey, null, true);

    this.props.setTypeaheadSearchValue(this.props.formKey, this.props.fieldKey, '');

    this.clearResults();
  };

  selectOption = (value) => {
    let selected;

    if (typeof value === 'object') {
      // If object, display value
      selected = value[this.props.displayKey];
    } else if (typeof value === 'number') {
      // Fuse returns indexes for Array<string>
      selected = this.props.options[value];
    } else {
      // Otherwise, its a string, so just use that
      selected = value;
    }

    this.props.setTypeaheadSearchValue(this.props.formKey, this.props.fieldKey, selected);

    this.props.setField(this.props.formKey, this.props.fieldKey, typeof value === 'number' ? selected : value, true);

    this.clearResults();
  };

  getResults = (search) => {
    if (search.length === 0) {
      this.setState({
        options: this.props.options,
      });
    } else {
      this.setState({
        options: this.fuse.search(search || ''),
      });
    }
  };

  clearResults = () => {
    this.setState({ options: [] });
  };

  searchValue = () => {
    // if there is a search, use the content of that
    if (typeof this.props.searchValue === 'string') {
      return this.props.searchValue;

      // If there is a field value, use the value of that
    } else if (this.props.value) {
      if (typeof this.props.value === 'object') {
        return this.props.value[this.props.displayKey];
      }

      return this.props.value;
    }

    return '';
  };

  // Material theme inputs need a specific placholder
  placeholder = () => {
    if (this.props.theme === 'material') {
      return ' ';
    }

    return this.props.placeholder || '';
  };

  options = () => {
    if (this.state.options.length) {
      const { options } = this.state;

      const results = options.map((opt, index) => {
        let display;
        if (typeof opt === 'object') {
          // If object, display value
          display = opt[this.props.displayKey];
        } else if (typeof opt === 'number') {
          // Fuse returns indexes for Array<string>
          display = this.props.options[opt];
        } else {
          // Otherwise, its a string, so just use that
          display = opt;
        }

        return (
          <div
            data-cy={`time_zone-${index}`}
            key={display}
            className={styles.option}
            onClick={() => {
              this.selectOption(opt);
            }}
          >
            {display}
          </div>
        );
      });

      return (
        <div
          ref={(dropdown) => {
            this.dropdown = dropdown;
          }}
          className={classnames(styles.results, styles[this.props.theme])}
        >
          <FlexCol>{results}</FlexCol>
        </div>
      );
    }

    return null;
  };

  render() {
    const showValidation = !!get('clientValidation.message', this.props) && (!!this.props.dirty || !!this.props.value);
    return (
      <InputWrapper full={this.props.full} inline={this.props.inline} valid={showValidation} theme={this.props.theme}>
        {this.options()}
        <div
          className={classnames(sharedStyles.inputField, styles.input, styles.customWrapper, {
            [sharedStyles.full]: this.props.full,
            [sharedStyles.invalid]: showValidation,
            [sharedStyles[this.props.theme]]: this.props.theme,
          })}
        >
          {this.props.inlineLabel && <div className={styles.inlineLabel}>{this.props.inlineLabel}</div>}
          <input
            data-cy={this.props.dataCy ? `${this.props.dataCy}-timezone` : ''}
            className={styles.basicInput}
            id={this.props.fieldKey}
            autoComplete="off"
            type={this.props.type}
            disabled={this.props.disabled}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            value={this.searchValue()}
            placeholder={this.placeholder()}
            max={this.props.max}
            min={this.props.min}
            maxLength={this.props.maxLength}
            minLength={this.props.minLength}
          />
          {(this.props.value || this.props.searchValue) && (
            <Icon className={sharedStyles.close} type="close" onClick={this.handleClickCloseIcon} />
          )}
        </div>
        {this.props.label && (
          <Section size="sm">
            <Label
              disabled={this.props.disabled}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}
              theme={this.props.theme}
              required={this.props.required}
            >
              <Stout inline>{this.props.label}</Stout>
            </Label>
          </Section>
        )}
      </InputWrapper>
    );
  }
}

export { StaticTypeahead };
export default connect(
  (state, ownProps) => ({
    ...formFieldSelector(state, ownProps),
    searchValue: get(`${ownProps.formKey}.meta.${ownProps.fieldKey}.searchValue`, state.form),
  }),
  { setField, setTypeaheadSearchValue }
)(StaticTypeahead);
