//

import React from 'react';
import classnames from 'classnames';

import ButtonBase from '../Base';

import styles from './styles.module.scss';

const Fab = (props) => (
  <ButtonBase
    color={props.color}
    className={classnames(styles.fab, styles[props.size], {
      [styles[props.color]]: props.color,
      [styles.actionButton]: props.actionButton,
      [styles.secondary]: props.secondary,
      [styles.pagination]: props.pagination,
      [styles.disabled]: props.disabled,
      [styles.square]: props.square,
    })}
    reverse={props.secondary}
    radius={props.square ? '3px' : '50%'}
    flat={props.flat}
    {...props}
  />
);

Fab.defaultProps = {
  size: 'md',
  color: 'success',
};

export default Fab;
