//

import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useOpenClose } from '../../../utils/hooks';

import styles from './styles.module.scss';

const Tooltip = (props) => {
  const [isOpen, { open, close }] = useOpenClose(false);
  const { noBaseIcon, placement, text, children } = props;

  useEffect(() => {
    const closeListener = (event) => {
      if (event?.key === 'Escape') {
        close();
      }
    };
    document.addEventListener('keydown', closeListener);
    return () => document.removeEventListener('keydown', closeListener);
  }, []);

  return (
    <div
      className={classnames(styles.tooltip, {
        'mdi mdi-information-outline': !noBaseIcon,
        [styles.open]: isOpen,
      })}
      onMouseEnter={open}
      onMouseLeave={close}
      onClick={() => {
        isOpen && close();
        !isOpen && open();
      }}
      onFocus={open}
      onBlur={close}
      tabIndex={-1}
      role="tooltip"
      aria-label={text}
      onKeyDown={(e) => {
        !isOpen && e.code === 'Enter' && open();
        isOpen && close();
      }}
    >
      <span
        className={classnames(styles.tooltipText, {
          [styles[placement]]: placement,
        })}
      >
        {text}
      </span>
      {children}
    </div>
  );
};

export default Tooltip;
