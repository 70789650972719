//
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';
import Stout from '../../typography/Stout';

import styles from './styles.module.scss';

import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';

class Checkbox extends Component {
  handleChange = (e) => {
    this.props.setField(this.props.formKey, this.props.fieldKey, e.target.checked, true);
  };

  sanitizeLabel = () => {
    if (typeof this.props.label === 'string') {
      return this.props.label ? this.props.label.replace(/ /g, '_').replace(/[^\w\d]/g, '') : '';
    }

    return 'label';
  };

  errors = () => {
    const status = !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
    if (status)
      return (
        <div
          role="alert"
          className={classnames(sharedStyles.errorMessage, {
            [sharedStyles.show]: status,
            [sharedStyles.hide]: !status,
            [sharedStyles[this.props.theme]]: this.props.theme,
          })}
        >
          <Stout uppercase={false} status="error">
            {this.props.errorMsg ? this.props.errorMsg : 'This field is required'}
          </Stout>
        </div>
      );
    return null;
  };

  render() {
    const checked = this.props.value ? this.props.value.toString() : undefined;
    const id = `${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel()}`;
    const showValidation = !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);

    return (
      <InputWrapper inline={this.props.inline} valid={showValidation} theme={this.props.theme} for="checkbox">
        <span
          className={classnames(styles.checkboxFieldWrapper, {
            [sharedStyles.full]: this.props.border,
            [sharedStyles.border]: this.props.border,
            [sharedStyles.activeBorder]: checked,
          })}
        >
          <input
            id={id}
            data-cy={this.props.dataCy ? this.props.dataCy + '-checkbox' : ''}
            className={classnames(sharedStyles.checkboxField, {
              [styles.invalid]: showValidation,
              [styles[this.props.theme]]: this.props.theme,
              [sharedStyles.invalid]: showValidation,
            })}
            type="checkbox"
            disabled={this.props.disabled}
            onChange={this.handleChange}
            checked={this.props.value}
            placeholder={this.props.placeholder}
            required={this.props.required}
          />
          {this.props.label && (
            <Label
              for={id}
              disabled={this.props.disabled}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}
              theme="simple"
              bolded={this.props.bolded}
              required={this.props.required}
            >
              {this.props.label}
            </Label>
          )}
        </span>
        {this.errors()}
      </InputWrapper>
    );
  }
}

export { Checkbox };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(Checkbox);
