//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Paper = (props) => (
  <div
    className={classnames(styles.default, {
      [styles[`depth-${props.depth}`]]: true,
      [styles.white]: props.white,
    })}
  >
    {props.children}
  </div>
);

Paper.defaultProps = {
  depth: 1,
  white: false,
};

export default Paper;
