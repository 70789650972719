//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const WideContainer = (props) => (
  <div
    className={classnames(styles.wide, {
      [styles.notCenter]: !props.center,
    })}
  >
    {props.children}
  </div>
);

WideContainer.defaultProps = {
  center: true,
};

export default WideContainer;
