//

import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';

import { Stout, Bolded } from '../../typography';
import InputWrapper from '../InputWrapper';
import Label from '../Label';
import DialCodeSelect from '../DialCodeSelect';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

// Material theme inputs need a specific placholder
const placeholder = (props) => {
  if (props.theme === 'material') {
    return ' ';
  }

  return props.placeholder || '';
};

const errors = (props, focus) => {
  let message = '';

  const valid = getOr(true, 'clientValidation.number.valid', props);

  if (!valid && !focus && !!props.dirty) {
    message = get('clientValidation.number.message', props);
  }

  if (!message) {
    return null;
  }

  return (
    <div
      role="alert"
      className={classnames(sharedStyles.errorMessage, {
        [sharedStyles.show]: !valid,
        [sharedStyles.hide]: valid,
        [sharedStyles[props.theme]]: props.theme,
      })}
    >
      <Stout uppercase={false} status="error">
        {message}
      </Stout>
    </div>
  );
};

const label = (props) => {
  if (props.label) {
    return (
      <legend className={styles.legend}>
        <Label
          disabled={props.disabled}
          formKey={props.formKey}
          fieldKey={props.fieldKey}
          theme={props.theme}
          bolded={props.bolded}
          raiseLabel={props.raiseLabel}
          required={props.required || props.forceRequired}
        >
          {props.bolded ? (
            <span className={styles.bolded}>
              <Bolded>{props.label}</Bolded>
            </span>
          ) : (
            <Stout inherit={props.inherit} inline>
              {props.label}
            </Stout>
          )}
        </Label>
      </legend>
    );
  }

  return null;
};

const showValidation = (props) => {
  // show if the field has an error and is dirty
  return !!get('clientValidation.number.message', props) && (!!props.dirty || !!props.value.number);
};

const handleChange = (e, props) => {
  const target = e.target;
  if (isNaN(target.value)) {
    return;
  }
  props.setField(props.formKey, props.fieldKey, props.value, true);

  props.setField(props.formKey, `${props.fieldKey}.number`, target.value, true);
};

const PhoneInput = (props) => {
  const [focus, setFocus] = useState(false);

  return (
    <InputWrapper
      full={props.full}
      inline={props.inline}
      valid={showValidation(props)}
      theme={props.theme}
      fixedSpacing={props.fixedSpacing}
    >
      {errors(props, focus)}
      <fieldset>
        <div className={styles.input}>
          <DialCodeSelect
            formKey={props.formKey}
            fieldKey={`${props.fieldKey}.code`}
            initialValue={props.value}
            fixedSpacing={props.fixedSpacing}
            dataCy={props.dataCy ? props.dataCy + '-code' : ''}
            selectId={props.dialCodeSelectId}
          />
          <input
            id={`${props.formKey}-${props.fieldKey}`}
            className={classnames(sharedStyles.inputField, styles.input, {
              [sharedStyles.full]: props.full,
              [sharedStyles.invalid]: showValidation(props),
              [sharedStyles[props.theme]]: props.theme,
              [sharedStyles.fixedHeight]: props.fixedHeight,
            })}
            type="tel"
            autoComplete="tel-national"
            disabled={props.disabled}
            onChange={(e) => handleChange(e, props)}
            value={props.value.number || ''}
            placeholder={placeholder}
            required={props.required || props.forceRequired}
            max={props.max}
            min={props.min}
            maxLength={props.maxLength}
            minLength={props.minLength}
            data-cy={props.dataCy ? props.dataCy + '-number-input' : ''}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          {props.bottomHighlight && <div className={styles.bar} />}
        </div>
        {label(props)}
      </fieldset>
    </InputWrapper>
  );
};

export { PhoneInput };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(PhoneInput);
