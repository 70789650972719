//
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';
import { Stout, Small } from '../../typography';
import { Section } from '../../layout';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

class Textarea extends Component {
  static defaultProps = {
    disabled: false,
    inline: false,
    full: false,
    placeholder: '',
    cols: 20,
    rows: 5,
  };

  state = { focused: false };

  handleChange = (e) => {
    const target = e.target;

    this.props.setField(this.props.formKey, this.props.fieldKey, target.value, true);
  };

  // Material theme inputs need a specific placholder
  placeholder = () => {
    if (this.props.theme === 'material') {
      return ' ';
    }

    return this.props.placeholder || '';
  };

  dirty = () => {
    return !!this.props.dirty;
  };

  errors = () => {
    const valid = get('clientValidation.valid', this.props);

    const showErrors = !valid && !this.state.focused && this.dirty();
    // don't show errors if focused, or if valid
    if (!showErrors) {
      return null;
    }

    const message = get('clientValidation.message', this.props);
    const showError = !isEmpty(message) && !valid && this.dirty();

    return (
      showError && (
        <div
          role="alert"
          className={classnames(sharedStyles.errorMessage, {
            [sharedStyles.show]: showError,
            [sharedStyles.hide]: !showError,
            [sharedStyles[this.props.theme]]: this.props.theme,
          })}
        >
          <Stout uppercase={false} status="error">
            {message}
          </Stout>
        </div>
      )
    );
  };

  onFocus = () => {
    this.props.onFocus && this.props.onFocus();

    this.setState({ focused: true });
  };

  onBlur = () => {
    this.props.onBlur && this.props.onBlur();

    this.setState({ focused: false });
  };

  render() {
    const showValidation = !!get('clientValidation.message', this.props) && !!(this.dirty() || this.props.value);
    return (
      <InputWrapper
        full={this.props.full}
        inline={this.props.inline}
        valid={showValidation}
        theme={this.props.theme}
        for="textarea"
      >
        {this.props.description && (
          <Section size="sm">
            <Small>{this.props.description}</Small>
          </Section>
        )}
        {this.errors()}
        <textarea
          id={`${this.props.formKey}-${this.props.fieldKey}`}
          data-cy={this.props.dataCy ? this.props.dataCy + '-textarea' : ''}
          className={classnames(sharedStyles.inputField, styles.textarea, {
            [sharedStyles.full]: this.props.full,
            [sharedStyles.invalid]: showValidation,
            [styles[this.props.theme]]: this.props.theme,
            [sharedStyles[this.props.theme]]: this.props.theme,
          })}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          value={this.props.value}
          placeholder={this.placeholder()}
          required={this.props.required}
          cols={this.props.cols}
          rows={this.props.rows}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {this.props.label && (
          <Label
            disabled={this.props.disabled}
            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            theme={this.props.theme}
            ariaLabel={this.props.ariaLabel}
          >
            <Stout inline>{this.props.label}</Stout>
          </Label>
        )}
      </InputWrapper>
    );
  }
}

export { Textarea };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(Textarea);
