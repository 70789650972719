import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
} from '@springcare/sh-component-library';

import { Form, CheckboxGroup, Select } from '../../form';
import Button from '../../buttons/Base';

import { FlexRow } from '../index';

import { MultiValue, Option } from './selectComponents';

import { mountTable, setActiveKeys } from '../../../actions/table/actions';
import { tableDataSelector } from '../../../selectors/table';
import { dataDogEvents } from 'modules/shared/constants';

class KeyToggle extends Component {
  static defaultProps = { keyToggle: [] };

  componentDidMount() {
    let activeKeys;
    if (localStorage.getItem(this.props.tableKey)) {
      activeKeys = JSON.parse(localStorage.getItem(this.props.tableKey));
    } else if (this.props.keyToggle.length) {
      // if there are toggle-able keys, use those for the active keys
      activeKeys = this.props.keyToggle;
    } else {
      // otherwise, just default to showing all keys
      activeKeys = Object.keys(this.props.keys);
    }

    this.props.mountTable(this.props.tableKey, activeKeys);
  }

  options = () => {
    return Object.entries(this.props.keys).map((pair) => ({
      label: pair[1],
      value: pair[0],
    }));
  };

  setActiveKeys = (data) => {
    let keyToggle = data.keyToggle;
    if (this.props.useRedesign) {
      keyToggle = data.map((selected) => selected.value);
    }
    datadogRum.addAction(dataDogEvents.QUERY_TABLE_KEY_TOGGLE, { table: this.props.tableKey, value: keyToggle });
    this.props.setActiveKeys(this.props.tableKey, keyToggle);
  };

  resetKeys = () => {
    const keys = this.props.keyToggle.length ? this.props.keyToggle : Object.keys(this.props.keys);
    this.props.setActiveKeys(this.props.tableKey, keys);
  };

  render() {
    if (this.props.keyToggle.length && this.props.activeKeys) {
      if (this.props.useRedesign) {
        return (
          <Select
            useRedesign
            fieldKey="keyToggle"
            options={this.options()}
            valueOverride={this.props.activeKeys}
            handleChange={this.setActiveKeys}
            placeholder="Manage Columns"
            clearable={false}
            hideSelectedOptions={false}
            customStyleOpts={{
              control: { height: '35px', overflowY: 'hidden' },
              option: { color: 'black', backgroundColor: 'white' },
            }}
            customComponents={{ MultiValue, Option }}
          />
        );
      }

      return (
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton justifyContent="space-between" backgroundColor="background-subtle">
              <Text textStyle="body-medium-strong">Select Table Keys</Text>
              <AccordionIcon />
            </AccordionButton>

            <AccordionPanel>
              <Form
                formKey={this.props.tableKey}
                onChange={this.setActiveKeys}
                initialData={{
                  keyToggle: this.props.activeKeys,
                }}
              >
                <CheckboxGroup inline fieldKey="keyToggle" options={this.options()} />
              </Form>

              <FlexRow justification="flex-end">
                <Button reverse color="warn" icon="undo" text="Reset" onClick={this.resetKeys} />
              </FlexRow>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    }

    return null;
  }
}

KeyToggle.propTypes = {
  setActiveKeys: PropTypes.func,
  useRedesign: PropTypes.bool,
  mountTable: PropTypes.func,
  keyToggle: PropTypes.arrayOf(PropTypes.string),
  keys: PropTypes.objectOf(PropTypes.string),
  tableKey: PropTypes.string,
  activeKeys: PropTypes.arrayOf(PropTypes.string),
};

export { KeyToggle };
export default connect((state, ownProps) => tableDataSelector(state, ownProps), { mountTable, setActiveKeys })(
  KeyToggle
);
