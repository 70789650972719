import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import { DateTime } from 'luxon';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';
import Stout from '../../typography/Stout';

import Picker from './Picker';
import styles from './styles.module.scss';

import { setField, setDatepickerDisplayDate } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

class DatePicker extends Component {
  static defaultProps = {
    disabled: false,
    type: 'text',
    placeholder: 'mm/dd/yyyy',
    value: '',
    readOnly: true,
    withTimestamp: false,
  };

  state = { showPicker: false, timestamp: undefined };

  handleInputChange = (e) => {
    let value = e.target.value || '';
    if (!value.length) {
      value = undefined;
    }

    const date = value ? DateTime.fromFormat(value, 'MM/dd/yyyy') : undefined;

    if (date && date.isValid) {
      this.props.setDatepickerDisplayDate(this.props.formKey, this.props.fieldKey, date);
    }

    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      value,
      true // sets dirty
    );

    this.closePicker();
  };

  handlePickerChange = (value) => {
    if (value) {
      this.props.setDatepickerDisplayDate(this.props.formKey, this.props.fieldKey, DateTime.fromISO(value));
    }
    this.setState({ timestamp: value });
  };

  label = () => {
    if (this.props.label) {
      return (
        <Label
          disabled={this.props.disabled}
          formKey={this.props.formKey}
          fieldKey={this.props.fieldKey}
          theme={this.props.theme}
        >
          <Stout inline>{this.props.label}</Stout>
        </Label>
      );
    }

    return null;
  };

  showValidation = () => {
    // show if the field has an error and is dirty
    return !!get('clientValidation.message', this.props) && (this.props.dirty || this.props.value);
  };

  picker = () => {
    if (this.state.showPicker) {
      return (
        <Picker
          dataCy={this.props.dataCy}
          formKey={this.props.formKey}
          fieldKey={this.props.fieldKey}
          theme={this.props.theme}
          open={this.state.showPicker}
          close={this.closePicker}
          earliest={this.props.earliest}
          latest={this.props.latest}
          fixed={this.props.fixed}
          withTimestamp={this.props.withTimestamp}
          onChange={this.handlePickerChange}
        />
      );
    }

    return null;
  };

  // Material theme inputs need a specific placholder
  placeholder = () => {
    if (this.props.theme === 'material') {
      return ' ';
    }

    return this.props.placeholder || '';
  };

  openPicker = () => {
    if (!this.props.disabled) {
      this.setState({ showPicker: true });
    }
  };

  closePicker = () => {
    this.setState({ showPicker: false });
  };

  render() {
    const showValidation = !!get('clientValidation.message', this.props) && (this.props.dirty || this.props.value);
    return (
      <InputWrapper
        theme={this.props.theme}
        valid={this.showValidation()}
        fixedSpacing={this.props.fixedSpacing}
        for="datepicker"
      >
        {this.picker()}
        <input
          id={`${this.props.formKey}-${this.props.fieldKey}`}
          autoComplete="off"
          className={classnames(styles.dateField, sharedStyles.inputField, {
            [sharedStyles.invalid]: this.showValidation(),
            [styles[this.props.theme]]: this.props.theme,
            [sharedStyles[this.props.theme]]: this.props.theme,
            [sharedStyles.fixedHeight]: this.props.fixedHeight,
          })}
          onClick={this.openPicker}
          onFocus={this.openPicker}
          value={this.state.timestamp ? DateTime.fromISO(this.state.timestamp).toFormat('f ZZ') : this.props.value}
          placeholder={this.placeholder()}
          onChange={this.handleInputChange}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
        />
        {this.label()}
      </InputWrapper>
    );
  }
}

export { DatePicker };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField, setDatepickerDisplayDate })(
  DatePicker
);
