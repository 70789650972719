//

import React from 'react';
import classnames from 'classnames';

import KeyToggle from '../KeyToggle';

import Body from './Body';
import Headers from './Headers';
import styles from './styles.module.scss';

const Table = (props) => {
  if (!props.tableKey) {
    return <h1 style={{ color: 'red' }}>Table Key is a required prop.</h1>;
  }

  return (
    <div>
      {!props.useRedesign && <KeyToggle tableKey={props.tableKey} keys={props.keys} keyToggle={props.keyToggle} />}

      <table
        className={classnames(styles.table, {
          [styles.striped]: props.striped,
          [styles.useSessionsDesign]: props.useSessionsDesign,
        })}
        data-cy={props.dataCy ? props.dataCy + '-table' : ''}
      >
        <Headers tableKey={props.tableKey} tooltips={props.tooltips} keys={props.keys} hideAt={props.hideAt} />

        <Body
          tableKey={props.tableKey}
          data={props.data}
          mutators={props.mutators}
          keys={props.keys}
          hideAt={props.hideAt}
        />
      </table>
    </div>
  );
};

export default Table;
