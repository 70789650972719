//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Underlined = (props) => (
  <span
    className={classnames(styles.underlined, {
      [styles.primary]: props.primary,
      [styles.grey]: props.grey || props.gray,
    })}
  >
    {props.children}
  </span>
);

export default Underlined;
