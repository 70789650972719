import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isNil from 'lodash/isNil';

import styles from './styles.module.scss';

const Col = (props) => (
  <div
    data-cy={props.dataCy ? props.dataCy + '-col' : ''}
    className={classnames(styles.col, {
      [styles[`col-${props.size}`]]: !isNil(props.size),
      [styles.break]: props.break,
      [styles.verticalPadding]: props.verticalPadding,
      [styles[`col-sm-${props.sm}`]]: !isNil(props.sm),
      [styles[`col-md-${props.md}`]]: !isNil(props.md),
      [styles[`col-lg-${props.lg}`]]: !isNil(props.lg),
      [styles[`col-xlg-${props.xlg}`]]: !isNil(props.xlg),
    })}
    style={{
      alignSelf: props.alignment,
      justifySelf: props.justification,
    }}
  >
    {props.children}
  </div>
);

Col.defaultProps = {
  break: false,
  verticalPadding: false,
};

Col.propTypes = {
  break: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node], PropTypes.node),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sm: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xlg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alignment: PropTypes.string,
  justification: PropTypes.string,
  verticalPadding: PropTypes.bool,
  dataCy: PropTypes.string,
};

export default Col;
