//
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import without from 'lodash/without';

import Label from '../Label';
import InputWrapper from '../InputWrapper';
import { Tag } from '../../atoms';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

class CheckboxTags extends Component {
  static defaultProps = { options: [] };

  handleChange = (e) => {
    let newValue;

    if (this.props.value.includes(e.target.value)) {
      newValue = without(this.props.value, e.target.value);
    } else {
      newValue = [...this.props.value, e.target.value];
    }

    this.props.setField(this.props.formKey, this.props.fieldKey, newValue, true);
  };

  tagCheckbox = (options, disabled) => {
    return (
      <input
        id={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel(options.label)}`}
        value={options.value}
        className={classnames(sharedStyles.checkboxField, {
          [styles.tagCheckbox]: options,
        })}
        type="checkbox"
        disabled={disabled}
        onChange={this.handleChange}
        checked={this.props.value.includes(options.value)}
      />
    );
  };

  sanitizeLabel = (label) => (label ? label.replace(/ /g, '_').replace(/[^\w\d]/g, '') : '');

  options = () =>
    this.props.options.map((opt) => {
      const checked = this.props.value.includes(opt.value);
      const tagCheckbox = this.tagCheckbox(opt, this.props.disabled);

      return (
        <Tag
          key={opt.label}
          text={opt.label}
          onChange={this.handleChange}
          checkbox={tagCheckbox}
          labelFor={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel(opt.label)}`}
          checked={checked}
          color={opt.color}
        />
      );
    });

  render() {
    const showValidation = !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
    return (
      <InputWrapper valid={showValidation} theme={this.props.theme} for="checkbox-group">
        <div className={classnames(styles.checkboxGroup)}>
          {this.props.label && (
            <Label
              disabled={this.props.disabled}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}
              theme={this.props.theme}
            >
              {this.props.label}
            </Label>
          )}
          <div className={classnames(styles.checkboxOptions)}>{this.options()}</div>
        </div>
      </InputWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => formFieldSelector(state, ownProps);
const mapDispatchToProps = { setField };

export { CheckboxTags };
export default connect(mapStateToProps, mapDispatchToProps)(CheckboxTags);
