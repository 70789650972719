//

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';

import Digital from './Digital';
import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

class TimePicker extends Component {
  static defaultProps = {
    disabled: false,
    inline: false,
    full: false,
    placeholder: '',
    type: 'text',
    interval: 30,
  };

  state = { showPicker: false };

  handleChange = (e) => {
    const target = e.target;

    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      target.value,
      true // sets dirty
    );
  };

  closePicker = () => {
    this.setState({ showPicker: false });
  };

  render() {
    const showValidation = !!get('clientValidation.message', this.props) && (this.props.dirty || this.props.value);
    return (
      <InputWrapper valid={showValidation} for="timepicker">
        <div className={styles.window}>
          {this.props.label && (
            <Label
              disabled={this.props.disabled}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}
              theme={this.props.theme}
            >
              {this.props.label}
            </Label>
          )}
          <input
            id={`${this.props.formKey}-${this.props.fieldKey}`}
            className={classnames(styles.dateField, sharedStyles.inputField, {
              [sharedStyles.invalid]: showValidation,
              [styles[this.props.theme]]: this.props.theme,
              [sharedStyles[this.props.theme]]: this.props.theme,
            })}
            onClick={() => {
              this.setState({ showPicker: true });
            }}
            value={this.props.value}
            placeholder={this.props.placeholder}
          />
          <div>
            {this.state.showPicker && this.props.theme === 'simple' && (
              <Digital
                formKey={this.props.formKey}
                fieldKey={this.props.fieldKey}
                interval={this.props.interval}
                start={this.props.start}
                end={this.props.end}
                placeholder={this.props.placeholder}
                close={this.closePicker}
              />
            )}
          </div>
        </div>
      </InputWrapper>
    );
  }
}

export { TimePicker };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(TimePicker);
