//
import React, { useEffect } from 'react';
import COLORS from '../../../constants/colors';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InlineIcon } from '@iconify/react';

import { clickableDivProps } from '../../../lib/accessibility_helpers';
import styles from './styles.module.scss';
import './material-icons.module.css';

const allowedIconColorHexes = Object.values(COLORS);

const Icon = (props) => {
  let style = { ...(props.style || {}) };

  const iconProps = {
    ...(props.iconProps || {}),
    'aria-hidden': false,
  };
  style.color = props.color;
  style.backgroundColor = props.background;

  return (
    <span style={{ alignItems: 'center' }}>
      <InlineIcon
        style={style}
        onClick={props.onClick}
        {...clickableDivProps({ onClick: props.onClick })}
        aria-label={props.type}
        aria-expanded={props.expanded}
        className={classnames(styles.default, props.className, {
          [styles.clickable]: props.onClick,
          [styles.background]: Boolean(props.background),
        })}
        icon={`mdi-${props.type}`}
        {...iconProps}
      />
    </span>
  );
};

Icon.propTypes = {
  color: PropTypes.oneOf(allowedIconColorHexes),
  bgColor: PropTypes.oneOf(allowedIconColorHexes),
};

Icon.defaultProps = {
  className: '',
  type: 'cake',
};

export default Icon;
