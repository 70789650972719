//

import React, { useRef, useEffect } from 'react';
import { useToggle } from '../../../utils/hooks';
import classnames from 'classnames';
import { connect } from 'react-redux';
import without from 'lodash/without';

import { Tag, Icon } from '../../atoms';
import { Stout } from '../../typography';
import { Breakpoint, FlexRow } from '../../layout';
import { Button } from '../../buttons';
import CheckboxGroup from '../CheckboxGroup';
import Label from '../Label';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

const MultiSelect = (props) => {
  const [menuOpen, toggleMenu] = useToggle();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (menuOpen || props.isOpen) {
      document.addEventListener('click', handleOutsideClick, false);
    }
    return () => document.removeEventListener('click', handleOutsideClick, false);
  }, [props.isOpen, menuOpen]);

  const handleOutsideClick = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (props.onClick) {
        return props.onClick();
      }

      return toggleMenu();
    }
  };

  const getTagLabel = (tagId) => {
    if (!props.options.length) {
      return null;
    }

    const option = props.options.find((option) => option.value === tagId);

    if (option) {
      return option.label;
    }
  };

  const dismissTag = (tagId) => props.setField(props.formKey, props.fieldKey, without(props.value, tagId), true);

  return (
    <div className={styles.selectWrapper} ref={wrapperRef}>
      <Label disabled={props.disabled} formKey={props.formKey} fieldKey={props.fieldKey}>
        <Stout inline>{props.label}</Stout>
      </Label>
      <div className={styles.select} onClick={props.onClick ? props.onClick : toggleMenu}>
        {!props.value.length && <p className={styles.placeholder}>{props.placeholder}</p>}
        {!!props.value.length &&
          props.value.map((tagId) => (
            <span key={tagId} className={styles.tagContainer}>
              <Tag
                text={getTagLabel(tagId)}
                onClick={(e) => {
                  e.stopPropagation();
                  dismissTag(tagId);
                }}
                color={props.tagColor}
                icon={<Icon type="close" />}
              />
            </span>
          ))}
      </div>
      {props.options && (
        <div
          className={classnames(styles.menu, {
            [styles.open]: props.isOpen ? props.isOpen : menuOpen,
            [styles[`menuSize-${props.menuSize}`]]: (props.isOpen || menuOpen) && props.menuSize,
          })}
        >
          <Breakpoint md andDown>
            <FlexRow justification="space-between" alignment="center" wrap="wrap">
              <div className={styles.mobileTitle}>
                <Stout>{props.label}</Stout>
              </div>
              {/* TODO: This isn't ideal, but the Button component itself is causing some issues with handleOutsideClick. Wrapping it in a parent div with a click handler solves this. */}
              <div onClick={props.onClick ? props.onClick : toggleMenu}>
                <Button text="Done" mini flat secondary />
              </div>
            </FlexRow>
          </Breakpoint>
          <CheckboxGroup
            fieldKey={props.fieldKey}
            formKey={props.formKey}
            options={props.options}
            columnCount={props.columnCount}
            column
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => formFieldSelector(state, ownProps);

const mapDispatchToProps = { setField };

export { MultiSelect };
export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect);
