//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const FlexCol = (props) => (
  <div
    className={classnames(styles.flexCol, props.className, {
      [styles.center]: props.center,
      [styles.reverse]: props.reverse,
      [styles.marginRight]: props.marginRight,
      [styles.shortHeight]: props.shortHeight,
    })}
    style={{
      alignItems: props.alignment,
      justifyContent: props.justification,
    }}
  >
    {props.children}
  </div>
);

export default FlexCol;
