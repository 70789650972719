//
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import without from 'lodash/without';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';
import { Stout } from '../../typography';

import styles from './styles.module.scss';
import RedesignGroupCheckbox from './RedesignGroupCheckbox';

import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';

class CheckboxGroup extends Component {
  static defaultProps = { options: [] };

  handleChange = (e) => {
    let newValue;

    if (this.props.value.includes(e.target.value)) {
      newValue = without(this.props.value, e.target.value);
    } else {
      newValue = [...this.props.value, e.target.value];
    }

    return this.props.setField(this.props.formKey, this.props.fieldKey, newValue, true);
  };

  label = () => {
    if (this.props.label) {
      return (
        <Label
          disabled={this.props.disabled}
          formKey={this.props.formKey}
          fieldKey={this.props.fieldKey}
          theme={this.props.theme}
        >
          <Stout inline>{this.props.label}</Stout>
        </Label>
      );
    }

    return null;
  };

  sanitizeLabel = (label) => {
    return label ? label.replace(/ /g, '_').replace(/[^\w\d]/g, '') : '';
  };

  fieldLabel = ({ label, sublabel }, disabled) => {
    if (label) {
      return (
        <>
          <Label
            for={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel(label)}`}
            disabled={disabled}
            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            theme={this.props.theme}
            required={false}
          >
            {/* REQUIRED=FALSE is used to turn of the required star manually */}
            {label}
            {sublabel && (
              <>
                <br />
                {sublabel}
              </>
            )}
          </Label>
        </>
      );
    }

    return null;
  };

  showValidation = () => {
    // show if the field has an error and is dirty
    return !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
  };

  options = () => {
    return this.props.options.map((opt) => {
      const checked = this.props.value.includes(opt.value);

      let disabled;
      if (this.props.max) {
        disabled = this.props.disabled || (this.props.value.length >= this.props.max && !checked);
      } else {
        disabled = this.props.disabled;
      }

      if (this.props.useRedesign) {
        return (
          <RedesignGroupCheckbox
            {...this.props}
            key={opt.label}
            opt={opt}
            checked={checked}
            disabled={disabled}
            showValidation={this.showValidation()}
            handleChange={this.handleChange}
            sanitizeLabel={this.sanitizeLabel}
            highlightHover={this.props.hoverHighlight}
            dataCy={this.props.dataCy}
          />
        );
      }

      return (
        <span
          dir="auto"
          className={classnames(styles.option, {
            [styles.inline]: this.props.inline,
            [styles.hideCheckbox]: this.props.hideCheckbox,
            [styles.centeredOption]: this.props.centeredOption,
            [styles[`column-${this.props.columnCount}`]]: this.props.columnCount,
            [sharedStyles.border]: this.props.border,
            [sharedStyles.activeBorder]: checked,
          })}
          key={opt.label}
        >
          <input
            id={`${this.props.formKey}-${this.props.fieldKey}--${this.sanitizeLabel(opt.label)}`}
            value={opt.value}
            className={classnames(sharedStyles.checkboxField, {
              [styles.invalid]: this.showValidation(),
              [styles[this.props.theme]]: this.props.theme,
              [sharedStyles.invalid]: this.showValidation(),
              [styles.hideCheckbox]: this.props.hideCheckbox,
            })}
            type="checkbox"
            data-cy={`${this.props.dataCy}-checkbox`}
            disabled={disabled}
            onChange={this.handleChange}
            checked={this.props.value.includes(opt.value)}
            placeholder={this.props.placeholder}
          />
          {this.fieldLabel({ label: opt.label, sublabel: opt.sublabel }, disabled)}
        </span>
      );
    });
  };

  render() {
    return (
      <InputWrapper
        inline={this.props.inline}
        valid={this.showValidation()}
        theme={this.props.theme}
        for="checkbox-group"
      >
        <div className={classnames(styles.checkboxGroup, { [sharedStyles.full]: this.props.border })}>
          {this.label()}
          <div
            className={classnames(styles.checkboxOptions, {
              [styles.hideCheckbox]: this.props.hideCheckbox,
              [styles.column]: this.props.column,
              [styles.useRedesign]: this.props.useRedesign,
            })}
          >
            {this.options()}
          </div>
        </div>
      </InputWrapper>
    );
  }
}

CheckboxGroup.defaultProps = {
  column: true,
};

export { CheckboxGroup };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(CheckboxGroup);
