//

import React, { useState, useEffect } from 'react';
import { countries } from '@spring/constants';
import get from 'lodash/fp/get';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Flex, Text } from '@springcare/sh-component-library';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import Icon from '../../atoms/Icon';
import InputWrapper from '../InputWrapper';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { lookupCountry } from '../../../utils/global';
import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

const handleChange = (e, setField, formKey, fieldKey, setSelectedCountry) => {
  const { value: countryName } = e.target;

  const country = lookupCountry(countryName);
  setSelectedCountry(country);

  setField(formKey, fieldKey, country.iso2, true);
};

const options = countries.map((opt) => (
  <option key={`${opt.name}-${opt.dialCode}`} value={opt.iso2}>
    {opt.displayName}
  </option>
));

const getDefaultCountry = (code = '', resCountry = '') => {
  const countryCode = (code && code.trim().toLowerCase()) || null;
  const userCountry = (resCountry && resCountry.trim().toLowerCase()) || null;

  if (countryCode) {
    const countryByCode = lookupCountry(countryCode);
    if (countryByCode) {
      return countryByCode;
    }
  }

  if (userCountry) {
    const country = lookupCountry(userCountry);
    if (country) {
      return country;
    }
  }

  return lookupCountry('us');
};

const showValidation = (props) => !!get('clientValidation.message', props) && (!!props.dirty || !!props.value);

const DialCodeSelect = (props) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const selectIdSuffix = props.selectId ? `-${props.selectId}` : '';
  const selectId = `select-country-for-country-code${selectIdSuffix}`;

  useEffect(() => {
    const defaultCountry = getDefaultCountry(props.initialValue?.code, props.initialValue?.country);
    setSelectedCountry(defaultCountry);
    props.setField(props.formKey, props.fieldKey, defaultCountry.iso2, true);
  }, []);

  return (
    <div
      className={classnames(styles.container, {
        [styles[props.theme]]: props.theme,
        [sharedStyles[props.theme]]: props.theme,
        [sharedStyles.invalid]: showValidation(props),
      })}
    >
      <InputWrapper valid={showValidation(props)} theme={props.theme} fixedSpacing={props.fixedSpacing} for="select">
        <div className={styles.selectWrapper}>
          <label htmlFor={selectId} hidden>
            Select country for country code
          </label>
          <select
            className={classnames(styles.selectField, sharedStyles.inputField, {
              [styles[props.theme]]: props.theme,
              [styles.initial]: props.value === '',
              [sharedStyles[props.theme]]: props.theme,
              [sharedStyles.invalid]: showValidation(props),
              [styles.withIcon]: props.withIcon,
            })}
            onChange={(e) => handleChange(e, props.setField, props.formKey, props.fieldKey, setSelectedCountry)}
            value={selectedCountry.iso2}
            placeholder={props.placeholder}
            style={{ paddingLeft: props.placeholderPadding }}
            aria-label="Select country for country code"
            data-cy={props.dataCy ? props.dataCy + '-select' : ''}
            id={selectId}
          >
            {options}
          </select>
        </div>
        <Flex position="absolute" top="8px" left="8px" align="center" justify="space-between">
          <Flex align="center">
            {selectedCountry?.iso2 && (
              <Text textStyle="heading-medium">{getUnicodeFlagIcon(selectedCountry.iso2)}</Text>
            )}
            <span className={styles.dialCode}>{`+${selectedCountry.dialCode}`}</span>
          </Flex>
          <Icon type="chevron-down" />
        </Flex>
      </InputWrapper>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => formFieldSelector(state, ownProps);

const mapDispatchToProps = { setField };

export { DialCodeSelect };
export default connect(mapStateToProps, mapDispatchToProps)(DialCodeSelect);
