//
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';

import Button from '../../buttons/Base';
import InputWrapper from '../InputWrapper';
import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';
import { formFieldSelector } from '../../../selectors/form';

class FileUpload extends Component {
  fileInput;
  fileName;

  handleChange = (e) => {
    const target = e.target;

    const name = target.value.split(/\\|\//).pop();
    this.fileName.value = name;

    this.props.setField(this.props.formKey, this.props.fieldKey, target.value, true);
  };

  clickFileInput = (e) => {
    e.preventDefault();

    if (this.fileInput) {
      this.fileInput.click();
    }
  };

  render() {
    const showValidation = !!get('clientValidation.message', this.props) && !!(this.props.dirty || this.props.value);
    return (
      <InputWrapper
        full={this.props.full}
        inline={this.props.inline}
        valid={showValidation}
        theme={this.props.theme}
        for="file"
      >
        <div className={styles.fileUploadWrapper}>
          <Label
            disabled={this.props.disabled}
            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            theme="simple"
          >
            <Button text={this.props.label || 'Browse...'} onClick={this.clickFileInput} />
            <input
              data-cy={this.props.dataCy ? `${this.props.dataCy}-file_upload` : ''}
              id={`${this.props.formKey}-${this.props.fieldKey}`}
              className={classnames(styles.fileInput, {
                [styles.invalid]: this.showValidation,
                [styles[this.props.theme]]: this.props.theme,
                [sharedStyles.invalid]: this.showValidation,
              })}
              type="file"
              onChange={this.handleChange}
              placeholder={this.props.placeholder}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, text/csv, application/pdf, image/*"
            />
          </Label>
          <input
            type="text"
            className={styles.fileName}
            readOnly
            ref={(fileName) => (this.fileName = fileName)}
          ></input>
        </div>
      </InputWrapper>
    );
  }
}

export { FileUpload };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(FileUpload);
