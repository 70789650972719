//

import React from 'react';
import classnames from 'classnames';

import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

const YearPicker = (props) => {
  return (
    <div className={styles.displayItem}>
      <input
        className={classnames(sharedStyles.inputField, sharedStyles.simple)}
        value={props.selectedYear}
        onChange={(e) => {
          props.onChange('year', e.target.value);
        }}
      />
    </div>
  );
};

export default YearPicker;
