import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Label from '../Label';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';

const RedesignGroupCheckbox = (props) => {
  const fieldLabel = ({ label, sublabel }, disabled) => {
    if (label) {
      return (
        <>
          <Label
            for={`${props.formKey}-${props.fieldKey}--${props.sanitizeLabel(label)}`}
            disabled={disabled}
            formKey={props.formKey}
            fieldKey={props.fieldKey}
            theme={props.theme}
            required={false}
          >
            {/* REQUIRED=FALSE is used to turn of the required star manually */}
            {label}
            {sublabel && (
              <>
                <br />
                {sublabel}
              </>
            )}
          </Label>
        </>
      );
    }

    return null;
  };

  return (
    <label
      className={classnames(styles.option, {
        [styles.inline]: props.inline,
        [styles.hideCheckbox]: props.hideCheckbox,
        [styles.centeredOption]: props.centeredOption,
        [styles[`column-${props.columnCount}`]]: props.columnCount,
        [sharedStyles.border]: props.border,
        [sharedStyles.activeBorder]: props.checked,
      })}
      key={props.opt.label}
    >
      <span
        className={classnames(styles.optionWrapper, {
          [styles.checked]: props.checked,
          [styles.isFixed]: props.opt.isFixed,
          [styles.highlightHover]: props.highlightHover,
        })}
      >
        <input
          id={`${props.formKey}-${props.fieldKey}--${props.sanitizeLabel(props.opt.label)}`}
          value={props.opt.value}
          className={classnames(sharedStyles.checkboxField, {
            [styles.invalid]: props.showValidation,
            [styles[props.theme]]: props.theme,
            [sharedStyles.invalid]: props.showValidation,
            [styles.hideCheckbox]: props.hideCheckbox,
            [styles.checked]: props.checked,
            [styles.isFixed]: props.opt.isFixed,
            [styles.highlightHover]: props.highlightHover,
          })}
          type="checkbox"
          disabled={props.disabled || props.opt.isFixed}
          onChange={props.handleChange}
          checked={props.value.includes(props.opt.value)}
          placeholder={props.placeholder}
          data-cy={`${props.dataCy}-${props.sanitizeLabel(props.opt.label)}`}
        />
        {fieldLabel({ label: props.opt.label, sublabel: props.opt.sublabel }, props.disabled)}
      </span>
    </label>
  );
};

RedesignGroupCheckbox.propTypes = {
  inline: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  centeredOption: PropTypes.object,
  columnCount: PropTypes.number,
  selectedCount: PropTypes.number,
  border: PropTypes.bool,
  checked: PropTypes.bool,
  highlightHover: PropTypes.bool,
  opt: PropTypes.shape({
    label: PropTypes.string,
    sublabel: PropTypes.string,
    value: PropTypes.array,
    isFixed: PropTypes.bool,
  }),
  theme: PropTypes.bool,
  disabled: PropTypes.bool,
  showValidation: PropTypes.func,
  formKey: PropTypes.string,
  fieldKey: PropTypes.string,
  placeholder: PropTypes.string,
  dataCy: PropTypes.string,
  value: PropTypes.shape({
    includes: PropTypes.func,
  }),
  sanitizeLabel: PropTypes.func,
  handleChange: PropTypes.func,
};

export default RedesignGroupCheckbox;
