//

import React, { Component } from 'react';
import { Info } from 'luxon';

import Icon from '../../atoms/Icon';

import styles from './styles.module.scss';

class MonthPicker extends Component {
  state = { showDropdown: false };

  dropdown = () => {
    if (this.state.showDropdown) {
      const months = Info.months('long').map((m, i) => (
        <div
          key={m}
          onClick={(e) => {
            e.stopPropagation();
            this.setState({ showDropdown: false });
            this.props.onChange('month', i + 1);
          }}
        >
          {m}
        </div>
      ));

      return <div className={styles.dropdown}>{months}</div>;
    }

    return null;
  };

  render() {
    return (
      <span
        className={styles.displayItem}
        onClick={() => {
          this.setState({ showDropdown: true });
        }}
      >
        {this.props.currentMonth} <Icon type="menu-down" />
        {this.dropdown()}
      </span>
    );
  }
}

export default MonthPicker;
