import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Grid = (props) => {
  const style = {
    alignItems: props.alignment,
    justifyItems: props.justification,
    gridGap: props.gutter,
  };

  return (
    <section
      style={style}
      className={classnames(styles.grid, {
        [styles.oneCol]: props.oneCol,
        [styles.twoCol]: props.twoCol,
        [styles.threeCol]: props.threeCol,
        [styles.fourCol]: props.fourCol,
      })}
    >
      {props.children}
    </section>
  );
};

export default Grid;
