const defaultCustomOpts = {
  container: {
    minWidth: '80px',
  },
  control: {
    cursor: 'pointer',
    borderColor: '#919191',
    overflowY: 'auto',
    height: '55px',
  },
  option: {
    cursor: 'pointer',
  },
  multiValueLabel: {
    padding: '8px',
  },
  multiValue: {
    borderRadius: '4px',
    backgroundColor: '#F0F7F6',
  },
};

export const customStyles = (opts = {}) => {
  const containerStyle = {
    ...defaultCustomOpts.container,
    ...(opts.container || {}),
  };
  const controlStyle = {
    ...defaultCustomOpts.control,
    ...(opts.control || {}),
  };
  const multiValueStyle = {
    ...defaultCustomOpts.multiValue,
    ...(opts.multiValue || {}),
  };
  const multiValueLabelStyle = {
    ...defaultCustomOpts.multiValueLabel,
    ...(opts.multiValueLabel || {}),
  };
  const optionStyle = { ...defaultCustomOpts.option, ...(opts.option || {}) };

  return {
    multiValue: (provided) => ({
      ...provided,
      ...multiValueStyle,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      ...multiValueLabelStyle,
    }),
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
    container: (provided) => ({
      ...provided,
      ...containerStyle,
    }),
    control: (provided) => ({
      ...provided,
      ...controlStyle,
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      ...optionStyle,
      backgroundColor:
        isFocused && optionStyle.backgroundColor ? '#C3DAD8' : optionStyle.backgroundColor || provided.backgroundColor,
    }),
  };
};

export const customTheme = (provided) => ({
  ...provided,
  colors: {
    ...provided.colors,
    primary: '#068464',
    primary25: '#C3DAD8',
    primary50: '#73A8A3',
  },
});
