//

import { MOUNT_TABLE, SET_ACTIVE_KEYS, SET_PER_PAGE } from './types';

export function mountTable(tableKey, activeKeys) {
  return {
    type: MOUNT_TABLE,
    tableKey,
    activeKeys,
  };
}

export function setActiveKeys(tableKey, activeKeys) {
  // Set the active keys into localStorage as well
  // so they can persist per user per table.
  // We must stringify to preserve the array
  localStorage.setItem(tableKey, JSON.stringify(activeKeys));
  return {
    type: SET_ACTIVE_KEYS,
    tableKey,
    activeKeys,
  };
}

export function setPerPage(tableKey, perPage) {
  return {
    type: SET_PER_PAGE,
    tableKey,
    perPage,
  };
}
