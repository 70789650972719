//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Number = (props) => <div className={classnames(styles.number, styles[props.color])}>{props.number}</div>;

export default Number;
