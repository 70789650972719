//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Backdrop = (props) => <div className={classnames(styles.backdrop, styles[props.color])}>{props.children}</div>;

Backdrop.defaultProps = {
  color: 'primary',
};

export default Backdrop;
