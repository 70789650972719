/* eslint-disable import/order */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import { formFieldSelector } from '../../../selectors/form';
import { setField } from '../../../actions/form';

import InputWrapper from '../InputWrapper';
import Label from '../Label';
import Stout from '../../typography/Stout';
import Icon from '../../atoms/Icon';
import Bolded from '../../typography/Bolded';
import sharedStyles from '../sharedStyles.module.scss';

import styles from './styles.module.scss';
import RedesignSelectInput from './RedesignSelectInput';

class Select extends Component {
  static defaultProps = {
    disabled: false,
    type: 'text',
    options: [],
    selectedTag: [],
    optionGroup: false,
    required: false,
    forceRequired: false,
  };

  handleChange = (e) => {
    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      e.target.value,
      true // sets dirty
    );
  };

  options = () => {
    let opts = this.props.options.slice(0).map((opt) => (
      <option
        key={`${opt.label}-${opt.value}`}
        value={opt.value}
        aria-label={opt.fullName || opt.label}
        disabled={opt.disabled}
      >
        {opt.fullName || opt.label}
        {/* a11y: Avoid abbrev for screen readers to properly speak it */}
      </option>
    ));

    if (this.props.placeholder) {
      opts.unshift(
        <option key={`placeholder=${this.props.placeholder}`} value="" disabled>
          {this.props.placeholder}
        </option>
      );
    }

    return opts;
  };

  render() {
    if (this.props.useRedesign) {
      return <RedesignSelectInput {...this.props} />;
    }

    const showValidation = !!get('clientValidation.message', this.props) && (this.props.dirty || this.props.value);

    return (
      <InputWrapper valid={showValidation} theme={this.props.theme} fixedSpacing={this.props.fixedSpacing} for="select">
        <div
          className={classnames(styles.selectWrapper, {
            [styles.selectWrapperLabelRowReverse]: this.props.labelRowReverse,
          })}
        >
          {this.props.inlineLabel && (
            <div className={styles.inlineLabel}>
              {this.props.inlineLabel}
              {this.props.required && <span>*</span>}
            </div>
          )}

          <select
            id={`${this.props.formKey}-${this.props.fieldKey}`}
            className={classnames(styles.selectField, sharedStyles.inputField, {
              [styles[this.props.theme]]: this.props.theme,
              [styles.initial]: this.props.value === '',
              [sharedStyles[this.props.theme]]: this.props.theme,
              [sharedStyles.invalid]: showValidation,
              [styles.withIcon]: this.props.withIcon,
              [sharedStyles.fixedHeight]: this.props.fixedHeight,
              [styles.selectFieldLabelRowReverse]: this.props.labelRowReverse,
            })}
            disabled={this.props.disabled}
            onChange={this.handleChange}
            value={this.props.value}
            placeholder={this.props.placeholder}
            style={{ paddingLeft: this.props.placeholderPadding }}
            aria-label={this.props.ariaLabel ? this.props.ariaLabel : 'Select Value'}
            data-cy={this.props.dataCy ? this.props.dataCy + '-select' : ''}
            required={this.props.forceRequired}
          >
            {this.options()}
          </select>

          {this.props.label && (
            <Label
              disabled={this.props.disabled}
              formKey={this.props.formKey}
              fieldKey={this.props.fieldKey}
              theme={this.props.theme}
              bolded={this.props.bolded}
              required={this.props.forceRequired}
            >
              {this.props.bolded ? (
                <span className={styles.bolded}>
                  <Bolded>
                    <>
                      {this.props.label}
                      {this.props.required && <span> * </span>}
                    </>
                  </Bolded>
                </span>
              ) : (
                <Stout inline>
                  {this.props.label}
                  {this.props.required && <span> * </span>}
                </Stout>
              )}
            </Label>
          )}
          {this.props.withIcon && (
            <div className={styles.icon}>
              <Icon type="chevron-down" />
            </div>
          )}
        </div>
      </InputWrapper>
    );
  }
}

Select.propTypes = {
  dirty: PropTypes.bool,
  disabled: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  fixedSpacing: PropTypes.bool,
  fieldKey: PropTypes.string,
  formKey: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  inlineLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  placeholderPadding: PropTypes.string,
  setField: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  valid: PropTypes.bool,
  clientValidation: PropTypes.object,
  withIcon: PropTypes.bool,
  useRedesign: PropTypes.bool,
  theme: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape),
  tracking: PropTypes.func,
  bolded: PropTypes.bool,
  dataCy: PropTypes.string,
  ariaLabel: PropTypes.string,
  labelRowReverse: PropTypes.bool,
  clearable: PropTypes.bool,
  multi: PropTypes.bool,
  required: PropTypes.bool,
  forceRequired: PropTypes.bool,
};

export { Select };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(Select);
