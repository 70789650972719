//
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import classnames from 'classnames';
import { useOutsideClick } from '../../../utils/hooks';
import FocusTrap from 'focus-trap-react';

import { Icon } from '../../atoms';
import { FlexRow } from '../index';

import styles from './styles.module.scss';

import { closeModal } from '../../../actions/modal';

const Modal = (props) => {
  const containerRef = useRef();
  const modalId = `modal-${props.id}`;
  const closeModal = (id) => {
    props.closeModal?.(id);
    props.onClose?.();
  };

  useEffect(() => {
    const close = (e) => {
      if (e.key === 'Escape') {
        closeModal(props.id);
        if (props.closeRedirect) {
          props.closeRedirect();
        }
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [props.open]);

  useOutsideClick(containerRef, () => {
    closeModal(props.id);
  });

  const closeButton = () => {
    if (!props.dismissable) {
      return null;
    }

    return (
      <div className={styles.closeWrapper}>
        <FlexRow justification="flex-end" alignment="center">
          <div
            className={styles.close}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && typeof props.closeModal === 'function') {
                closeModal(props.id);
                if (props.closeRedirect) {
                  props.closeRedirect();
                }
              }
            }}
          >
            <Icon
              type="close"
              onClick={() => {
                closeModal(props.id);
                if (props.closeRedirect) {
                  props.closeRedirect();
                }
              }}
            />
          </div>
        </FlexRow>
      </div>
    );
  };

  return props.open ? (
    <FocusTrap
      focusTrapOptions={{
        fallbackFocus: () => {
          return document.getElementById(modalId);
        },
        allowOutsideClick: true,
      }}
    >
      <div
        className={classnames(styles.overlay, {
          [styles.open]: props.open,
        })}
        onClick={() => {
          if (props.dismissable) {
            closeModal(props.id);
            if (props.closeRedirect) {
              props.closeRedirect();
            }
          }
        }}
        role="dialog"
        aria-label={props.title}
        aria-modal="true"
      >
        <div id={modalId} tabIndex="-1" className={styles.wrapper}>
          <div className={classnames(styles.modal, styles[props.size])}>
            <div
              className={classnames(styles.body, {
                [styles.gradient]: props.gradient,
                [styles.noScrollNeeded]: props.noScrollNeeded,
              })}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {closeButton()}

              <div className={styles.content}>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  ) : null;
};

Modal.defaultProps = {
  open: false,
  size: 'sm',
  closeRedirect: false,
  gradient: false,
  dismissable: true,
  title: '',
  onClose: () => {},
};

export { Modal };

export default connect(
  (state, ownProps) => ({
    open: getOr(ownProps.open, `${ownProps.id}.open`, state.modal),
    data: get(`${ownProps.id}.data`, state.modal),
  }),
  { closeModal }
)(Modal);
