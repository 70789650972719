//

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';
import get from 'lodash/fp/get';
import { timeFormats } from '@spring/constants';

import Col from '../../../layout/Grid/Col';
import Grid from '../../../layout/Grid/Grid';

import styles from './styles.module.scss';

import { setField } from '../../../../actions/form';
import { formFieldSelector } from '../../../../selectors/form';

class Digital extends Component {
  static defaultProps = {
    disabled: false,
    inline: false,
    full: false,
    placeholder: '',
    type: 'text',
    value: 'now',
  };

  picker;

  componentDidMount() {
    if (document) {
      document.addEventListener('mousedown', this.handleClick);
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener('mousedown', this.handleClick);
    }
  }

  handleClick = (e) => {
    if (this.picker && !this.picker.contains(e.target)) {
      this.props.close();
      document.removeEventListener('mousedown', this.handleClick);
    }
  };

  handleChange = (time) => {
    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      time,
      true // sets dirty
    );
  };

  options = () => {
    // get total minutes between 2 times
    // start at start, add interval until >= end
    // return grid of all those items.
    let start = DateTime.fromFormat(this.props.start, timeFormats.timePickerFormat);
    const end = DateTime.fromFormat(this.props.end, timeFormats.timePickerFormat);

    let times = [];

    // Fix for https://springhealth.atlassian.net/browse/CGX-1653
    // It seems some new js optimization is only creating a single function
    // with an in-line onClick defined below in <span />, so do this instead
    const onClick = (time) => {
      return () => {
        this.handleChange(time);
        this.props.close();
      };
    };

    // $FlowFixMe flow-typed thinks `length` isn't on `Duration`
    while (start.until(end).length() > 0) {
      const time = start.toFormat(timeFormats.timePickerFormat);

      times.push(
        <Col key={time} size={4}>
          <span
            onClick={onClick(time)}
            className={classnames(styles.option, {
              [styles.selected]: this.props.value === time,
            })}
          >
            {time}
          </span>
        </Col>
      );
      start = start.plus({ minutes: this.props.interval });
    }

    return <Grid>{times}</Grid>;
  };

  render() {
    return (
      <div
        ref={(picker) => {
          this.picker = picker;
        }}
        className={styles.digitalTime}
      >
        {this.options()}
      </div>
    );
  }
}

export { Digital };
export default connect((state, ownProps) => formFieldSelector(state, ownProps), { setField })(Digital);
