import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import { DateTime, Info } from 'luxon';
import { timeFormats } from '@spring/constants';

import Icon from '../../atoms/Icon';
import Floaty from '../../layout/Floaty';
import FlexRow from '../../layout/FlexRow';

import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';
import styles from './styles.module.scss';

import { setField, setDatepickerDisplayDate } from '../../../actions/form/actions';
import { Toggle, Input } from '@spring/smeargle';

class Picker extends Component {
  static defaultProps = {
    displayDate: DateTime.local(),
  };

  state = {
    today: DateTime.local(),
  };

  picker;

  // Redux formData keys for timestamp-related functionality
  timePickerToggleKey = this.props.fieldKey + '_timestamp_toggle'; // e.g., starting_after_timestamp_toggle
  timePickerTimeKey = 'timestamp_for_' + this.props.fieldKey; // e.g., timestamp_for_starting_after
  timePickerDateTimeKey = this.props.fieldKey + '_timestamp'; // e.g., starting_after_timestamp

  componentDidMount() {
    if (document) {
      document.addEventListener('mousedown', this.handleClick);
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener('mousedown', this.handleClick);
    }

    // If "With Time" is toggled on, but the input is empty, toggle off when hiding Picker
    if (this.props.formData[this.timePickerToggleKey] && !this.props.formData[this.timePickerTimeKey]) {
      this.props.setField(this.props.formKey, this.timePickerToggleKey, false, true);
    }
  }

  componentDidUpdate(prevProps) {
    this.handleTimeState(prevProps);
  }

  /**
   * NOTE: Set/Update an ISO-8601 datetime string that is the combination of the date value
   * (e.g., '03/09/2023') represented by {{ fieldKey }} in the Redux store, and of a vanilla 24H
   * time input (e.g., '13:30') represented by {{ this.timePickerTimeKey }}. The resulting ISO
   * string is represented by {{ this.timePickerDateTimeKey }} in the store, which is
   * {{ fieldKey }}_timestamp.
   *
   * Using this.props.withTimestamp therefore ASSUMES that the endpoint to which DatePicker's
   * value is being submitted not only accepts a field like 'date_of_appointment', but ALSO
   * 'date_of_appointment_timestamp'.
   */
  handleTimeState = (prevProps) => {
    if (!this.props.formData[this.timePickerToggleKey]) {
      // Clear out the Redux field holding the time (i.e., '23:59')
      if (this.props.formData[this.timePickerTimeKey]) {
        this.props.setField(this.props.formKey, this.timePickerTimeKey, undefined, true);
      }

      // Clear out the Redux field holding the combined datetime
      if (this.props.formData[this.timePickerDateTimeKey]) {
        this.props.setField(this.props.formKey, this.timePickerDateTimeKey, undefined, true);
      }

      // Pass the changes back up to DatePicker, mainly for updating the input display
      this.props.onChange(undefined);
    }

    if (this.props.formData[this.timePickerToggleKey]) {
      // If there is a change in the date or time picked, update the timestamp.
      if (
        prevProps.value !== this.props.value ||
        prevProps.formData[this.timePickerTimeKey] !== this.props.formData[this.timePickerTimeKey]
      ) {
        const timestampString = `${this.props.value} ${this.props.formData[this.timePickerTimeKey]}`;
        const date = DateTime.fromFormat(timestampString, 'MM/dd/yyyy HH:mm').toISO();

        this.props.setField(this.props.formKey, this.timePickerDateTimeKey, date, true);
      }
    }

    if (prevProps.formData[this.timePickerDateTimeKey] !== this.props.formData[this.timePickerDateTimeKey]) {
      // Pass the changes back up to DatePicker, mainly for updating the input display
      this.props.onChange(this.props.formData[this.timePickerDateTimeKey]);
    }
  };

  handleChange = (date) => {
    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      date,
      true // sets dirty
    );
  };

  handleClick = (e) => {
    if (this.picker && !this.picker.contains(e.target)) {
      this.props.close();
      document.removeEventListener('mousedown', this.handleClick);
    }
  };

  setDisplayMonth = (operation, amount) => {
    let newDate;

    if (operation === 'subtract') {
      newDate = this.props.displayDate.minus({ months: amount });
    } else if (operation === 'add') {
      newDate = this.props.displayDate.plus({ months: amount });
    }

    this.props.setDatepickerDisplayDate(this.props.formKey, this.props.fieldKey, newDate);
  };

  jumpToDate = (unit, newValue) => {
    const newDate = this.props.displayDate.set({ [unit]: newValue });

    if (newValue >= 0 && newValue < 3000) {
      this.props.setDatepickerDisplayDate(this.props.formKey, this.props.fieldKey, newDate);
    }
  };

  selected = () => {
    let date;
    const placeholder = 'mm/dd/yyyy';

    if (this.props.formData[this.timePickerDateTimeKey]) {
      date = DateTime.fromISO(this.props.formData[this.timePickerDateTimeKey], { setZone: true });
      return date && date.isValid ? date.toFormat('f ZZ') : placeholder;
    }

    date = DateTime.fromFormat(this.props.value || '', timeFormats.datePickerFormat);
    return date && date.isValid ? date.toFormat('D') : placeholder;
  };

  displayDate = () => {
    const month = this.props.displayDate.toFormat('MMMM');
    const selectedYear = this.props.displayDate.toFormat('y');
    const currentYear = this.state.today.toFormat('y');

    return (
      <div>
        <div className={styles.monthPicker}>
          <MonthPicker onChange={this.jumpToDate} currentMonth={month} />
        </div>
        <div className={styles.yearPicker}>
          <YearPicker onChange={this.jumpToDate} currentYear={currentYear} selectedYear={selectedYear} />
        </div>
      </div>
    );
  };

  headers = () => {
    const weekdays = Info.weekdays('short');

    return weekdays.map((w) => <div key={w}>{w}</div>);
  };

  calendar = () => {
    const display = this.props.displayDate;
    const selected = DateTime.fromFormat(this.props.value || '', timeFormats.datePickerFormat);

    const daysInMonth = display.daysInMonth; // 28 for example
    const firstDay = display.startOf('month').weekday - 1; // -1 to make 0 indexed
    const lastDay = display.endOf('month').weekday - 1; // -1 to make 0 indexed

    let calendarDays = [];

    for (let i = 0; i < firstDay; i++) {
      calendarDays.push('');
    }

    for (let i = 0; i < daysInMonth; i++) {
      calendarDays.push(i + 1);
    }

    for (let i = 6; i > lastDay; i--) {
      calendarDays.push('');
    }

    const earliest = DateTime.fromISO(this.props.earliest || '').set({ hour: 0, minute: 0, second: 0 }); // start of day

    const latest = DateTime.fromISO(this.props.latest || '').set({ hour: 24, minute: 59, second: 59 }); // end of day

    return calendarDays.map((d, i) => {
      if (!d) {
        return <div key={`${this.props.displayDate.month}-${i}`} className={styles.calendarCell} />;
      }

      let isDisabled;

      const cellDate = display.set({ day: +d });
      const isSelected = selected.toFormat('DDD') === cellDate.toFormat('DDD');
      const isToday = this.state.today.toFormat('DDD') === cellDate.toFormat('DDD');

      if (this.props.earliest) {
        isDisabled = DateTime.min(cellDate, earliest) === cellDate;
      }

      if (this.props.latest && !isDisabled) {
        // don't want to override
        isDisabled = DateTime.max(cellDate, latest) === cellDate;
      }

      return (
        <div
          key={`${this.props.displayDate.month}-${i}`}
          className={classnames(styles.calendarCell, {
            [styles.dateCell]: +d > 0,
            [styles.selected]: isSelected,
            [styles.today]: isToday,
            [styles.disabled]: isDisabled,
          })}
          onClick={() => {
            if (!isDisabled) {
              const newDate = display.set({ day: +d });

              this.handleChange(newDate.toFormat(timeFormats.datePickerFormat));
              this.props.setDatepickerDisplayDate(this.props.formKey, this.props.fieldKey, newDate);
            }
          }}
        >
          {d}
        </div>
      );
    });
  };

  render() {
    return (
      <div
        data-cy={this.props.dataCy}
        ref={(picker) => {
          this.picker = picker;
        }}
        onClick={this.handleClick}
        className={classnames(styles.picker, {
          [styles.open]: this.props.open,
          [styles.fixed]: this.props.fixed,
          [styles[this.props.theme]]: this.props.theme,
        })}
        role="dialog"
        aria-modal="true"
        aria-label={`Choose Date for ${this.props.fieldKey}`}
      >
        <Floaty float={2}>
          <div className={styles.header}>
            <FlexRow justification="space-between">
              <div className={styles.selected}>{this.selected()}</div>
              <div className={styles.close}>
                <Icon type="close" onClick={this.props.close} />
              </div>
            </FlexRow>
          </div>

          <div className={styles.content}>
            <div className={styles.controls}>
              <Icon
                type="chevron-left"
                onClick={() => {
                  this.setDisplayMonth('subtract', 1);
                }}
              />

              {this.displayDate()}

              <Icon
                type="chevron-right"
                onClick={() => {
                  this.setDisplayMonth('add', 1);
                }}
              />
            </div>

            <div className={styles.dateHeaders}>{this.headers()}</div>

            <div className={styles.calendar}>{this.calendar()}</div>

            {this.props.withTimestamp && (
              <div style={{ marginTop: '16px' }}>
                <Toggle
                  formKey={this.props.formKey}
                  fieldKey={this.timePickerToggleKey}
                  topLabel="With Time"
                  disabled={!this.props.formData[this.props.fieldKey]}
                  theme="simple"
                />
                {this.props.formData?.[this.timePickerToggleKey] && (
                  <Input formKey={this.props.formKey} fieldKey={this.timePickerTimeKey} theme="simple" type="time" />
                )}
              </div>
            )}
          </div>
        </Floaty>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    dirty: getOr(false, `${ownProps.formKey}.meta.${ownProps.fieldKey}.dirty`, state.form),
    value: getOr('', `${ownProps.formKey}.data.${ownProps.fieldKey}`, state.form) || '',
    theme: get(`${ownProps.formKey}.theme`, state.form),
    displayDate: getOr(DateTime.local(), `${ownProps.formKey}.meta.${ownProps.fieldKey}.displayDate`, state.form),
    formData: state.form?.[ownProps.formKey]?.data || {},
  }),
  { setField, setDatepickerDisplayDate }
)(Picker);
