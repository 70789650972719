//

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import getOr from 'lodash/fp/getOr';

import Stout from '../../typography/Stout';
import InputWrapper from '../InputWrapper';
import Label from '../Label';

import styles from './styles.module.scss';

import { setField } from '../../../actions/form';

class Toggle extends Component {
  static defaultProps = {
    disabled: false,
    inline: false,
    full: false,
    placeholder: '',
    type: 'text',

    simple: false,
    small: false,
    large: false,
    noMargin: false,
    ariaLabel: '',
  };

  handleChange = (e) => {
    this.props.setField(
      this.props.formKey,
      this.props.fieldKey,
      e.target.checked,
      true // sets dirty
    );
  };

  render() {
    const showValidation = !this.props.valid && (this.props.dirty || this.props.value);
    return (
      <InputWrapper
        full={this.props.full}
        inline={this.props.inline}
        valid={showValidation}
        noMargin={this.props.noMargin}
        theme={this.props.theme}
      >
        <div
          className={classnames(styles.default, {
            [styles.simple]: this.props.simple,
            [styles.native]: this.props.native,
            [styles.small]: this.props.small,
            [styles.large]: this.props.large,
            [styles.inForm]: this.props.inForm,
            [styles.locked]: this.props.locked,
          })}
        >
          {this.props.topLabel && (
            <Label disabled={this.props.disabled} formKey={this.props.formKey} fieldKey={this.props.fieldKey}>
              <Stout inline>{this.props.topLabel}</Stout>
            </Label>
          )}
          {/* Only one of `checked` and `defaultChecked` can be used with an input, so we need two separate inputs */}
          {this.props.defaultChecked ? (
            <input
              data-cy={this.props.dataCy ? `${this.props.dataCy}-checkbox` : ''}
              id={`${this.props.formKey}-${this.props.fieldKey}`}
              type="checkbox"
              onClick={this.handleChange}
              readOnly={true}
              disabled={this.props.disabled}
              arial-label={this.props.ariaLabel}
              defaultChecked
            />
          ) : (
            <input
              data-cy={this.props.dataCy ? `${this.props.dataCy}-checkbox` : ''}
              id={`${this.props.formKey}-${this.props.fieldKey}`}
              type="checkbox"
              onClick={this.handleChange}
              readOnly={true}
              checked={this.props.value || false}
              disabled={this.props.disabled}
              aria-label={this.props.ariaLabel}
            />
          )}
          <label
            htmlFor={`${this.props.formKey}-${this.props.fieldKey}`}
            data-cy={`${this.props.formKey}-${this.props.fieldKey}`}
            className={classnames()}
          />
          {this.props.bottomLabel && (
            <Label fieldKey={this.props.fieldKey} formKey={this.props.formKey}>
              {this.props.bottomLabel}
            </Label>
          )}
        </div>
        {this.props.label && (
          <Label
            disabled={this.props.disabled}
            formKey={this.props.formKey}
            fieldKey={this.props.fieldKey}
            theme={this.props.theme}
          >
            <Stout inherit={this.props.inherit} inline>
              {this.props.label}
            </Stout>
          </Label>
        )}
      </InputWrapper>
    );
  }
}

export { Toggle };
export default connect(
  (state, ownProps) => ({
    dirty: getOr(false, `${ownProps.formKey}.meta.${ownProps.fieldKey}.dirty`, state.form),
    value: getOr('', `${ownProps.formKey}.data.${ownProps.fieldKey}`, state.form),
  }),
  { setField }
)(Toggle);
