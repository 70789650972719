//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const HorizontalRule = (props) => {
  const isStandard = props.standard || !props.light;
  return (
    <div
      className={classnames(styles.horizontalRule, {
        [styles.pad]: props.pad,
        [styles.standard]: isStandard,
        [styles.light]: props.light,
      })}
    />
  );
};

export default HorizontalRule;
