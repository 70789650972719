import { countries } from '@spring/constants';
/* TODO: Extract this into a shared utils package. These functions are duplicates of those in Cherrim's utils/global file,
but they are needed for DialCodeSelect.js and StateSelect.js */

const unitedStates = countries.find((entry) => entry.iso2 === 'us');

const normalizeLongNames = (country) => {
  // Sometimes, country names are encoded with "%2B" in the query param, which decodes to "+".
  const searchCountry = country.replace(/\+/g, ' ');
  if (searchCountry.includes('united states')) {
    return 'united states of america';
  } else if (searchCountry.includes('united kingdom')) {
    return 'united kingdom of great britain and northern ireland';
  }
  return searchCountry;
};

const getCountryByKey = (country, format) => {
  return countries.find((entry) => entry[format] === country);
};

export const lookupCountry = (country) => {
  if (!country) {
    return null;
  }

  let searchCountry = country.trim().toLowerCase();

  if (searchCountry.length === 2) {
    return getCountryByKey(searchCountry, 'iso2') || null;
  } else if (searchCountry.length === 3) {
    return getCountryByKey(searchCountry, 'iso3') || null;
  }

  // Catch long name variants and remove '+' characters
  searchCountry = normalizeLongNames(searchCountry);
  return getCountryByKey(searchCountry, 'name') || null;
};

const confirmCountryExists = (country, format) => {
  return !!countries.find((entry) => entry[format] === country);
};

const getDesiredFormatByKey = (country, inputFormat, outputFormat) => {
  const entry = getCountryByKey(country, inputFormat);
  if (!entry || !entry[outputFormat]) {
    // Default to United States if given country does not exist
    return unitedStates[outputFormat];
  }

  return entry[outputFormat];
};

export const normalizeToISO2 = (country) => {
  if (!country) {
    return 'US';
  }

  let searchCountry = country.trim().toLowerCase();

  if (searchCountry.length === 2) {
    // If the given ISO2 value doesn't correspond with a country, return US
    return confirmCountryExists(searchCountry, 'iso2') ? searchCountry.toUpperCase() : 'US';
  } else if (searchCountry.length === 3) {
    return getDesiredFormatByKey(searchCountry, 'iso3', 'iso2').toUpperCase();
  }

  // Catch long name variants and remove '+' characters
  searchCountry = normalizeLongNames(searchCountry);
  return getDesiredFormatByKey(searchCountry, 'name', 'iso2').toUpperCase();
};
